import React from "react";
import { useState, useEffect } from "react";
import s from "../style/home.module.css";
import star from "../assets/svg/Frame 85.svg";
import location from "../assets/svg/location.svg";
import mail from "../assets/svg/sms.svg";
import calendar from "../assets/svg/calendar.svg";
import MainTourBlock from "../components/MainTourBlock";
import balloon from "../assets/svg/Balloon.svg";
import booking from "../assets/images/Booking.Com-logo.png";
import jot from "../assets/images/jot-logo-line-colour.png";
import freetour from "../assets/images/Freetour.com-logo-transparent.png";
import hostelworld from "../assets/images/hostelworld-logo-vector.jpg";
import viator from "../assets/images/viator.png";
import VanillaCalendar from "../components/VanillaCalendar.tsx";
import "../index.css";
import bigtree from "../assets/svg/bigtree.svg";
import Footer from "../components/Footer.jsx";
import Header from "../components/Header.jsx";
import plane from "../assets/svg/plane.svg";
import IncreasingNumber from "../components/IncreasingNumber.jsx";
import TourLoader from "../components/TourLoader.jsx";
import toyota from "../assets/images/toyota.jpg";
import Sponsor from "../components/Sponsor.jsx";
import expedia from "../assets/images/Expedia-Logo.png";
import googleLogo from "../assets/images/Google_Cloud_Platform-Logo.png";
import closeCircle from "../assets/svg/close-circle.svg";

const Home = () => {
  const [interests, setInterests] = useState([]);
  const [datesString, setDatesString] = useState("Select dates");
  const [dates, setDates] = useState([]);
  const [calendarShouldShow, setCalendarShouldShow] = useState(false);
  const [destination, setDestination] = useState("");
  const [interestsSelected, setInterestsSelected] = useState([]);
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [generatedTripsNumber, setGeneratedTripsNumber] = useState(0);
  const [destinationsNumber, setDestinationsNumber] = useState(0);
  const [subscribeTreeMail, setSubscribeTreeMail] = useState("");
  const [subscribeVisible, setSubscribeVisible] = useState(false);
  const [subscribeVisibleExists, setSubscribeVisibleExists] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getTotalTrips`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setGeneratedTripsNumber(data[0].total_trips);
      });

    const api2 = `${process.env.REACT_APP_API_URL}/getTotalDestinations`;
    fetch(api2)
      .then((response) => response.json())
      .then((data) => {
        setDestinationsNumber(data.total_destinations);
      });
  }, []);

  const [calendarConfig, setCalendarConfig] = useState({
    type: "multiple",
    months: "2",
    date: {
      min: new Date(),
    },
    settings: {
      visibility: {
        theme: "light",
      },
      range: {
        disablePast: true,
      },
      selection: {
        day: "multiple-ranged",
      },
    },
    actions: {
      clickDay: (e, self) => {
        const startDate = self["selectedDates"][0];
        const endDate = self["selectedDates"][self["selectedDates"].length - 1];
        localStorage.setItem("startDate", startDate);
        localStorage.setItem("endDate", endDate);
      },
    },
  });

  useEffect(() => {
    const updateCalendarMonths = () => {
      if (window.innerWidth < 600) {
        setCalendarConfig((prevConfig) => ({
          ...prevConfig,
          months: "1",
        }));
      } else {
        setCalendarConfig((prevConfig) => ({
          ...prevConfig,
          months: "2",
        }));
      }
    };

    // Initial check
    updateCalendarMonths();

    // Add event listener for window resize
    window.addEventListener("resize", updateCalendarMonths);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateCalendarMonths);
    };
  }, []);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/getHomeTexts`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoaded(true);
      });
  }, []);

  function generateUnqiueID() {
    const timestamp = new Date().getTime(); // Current timestamp
    const randomNum = Math.floor(Math.random() * 1000000); // Random number
    const uniqueString = `${timestamp}${randomNum}`;
    return uniqueString;
  }

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    if (isNaN(date)) {
      return "Invalid Date";
    }
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthAbbr = months[date.getMonth()];
    const day = date.getDate();
    return `${monthAbbr} ${day}`;
  }

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getInterests`;

    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        let array = [];
        for (let i = 0; i < data.length; i++) {
          array.push(data[i].title);
        }
        setInterests(array);
      });
  }, []);

  function handleInterestClick(event) {
    event.target.classList.toggle(s.interestButtonSelected);
    setInterestsSelected([...interestsSelected, event.target.innerText]);
  }

  useEffect(() => {
    const currentUrlTemp = window.location.origin;
    setCurrentUrl(currentUrlTemp);
  }, []);

  function setDatesClick() {
    if (localStorage.getItem("startDate") && localStorage.getItem("endDate")) {
      const startDate = localStorage.getItem("startDate");
      const endDate = localStorage.getItem("endDate");
      const differenceInMs = endDate - startDate;
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const differenceInDays = differenceInMs / millisecondsPerDay;
      if (differenceInDays + 1 >= 14) {
        alert("Please select dates that are less than 14 days");
        return;
      } else {
        setDatesString(formatDate(startDate) + " - " + formatDate(endDate));
        setDates([startDate, endDate]);
        setCalendarShouldShow(!calendarShouldShow);
      }
    } else {
      alert("Please select dates");
    }
  }

  function handleCalendarClick(event) {
    if (calendarShouldShow) {
      if (event.target.className === s.datePickerDiv) {
        setCalendarShouldShow(!calendarShouldShow);
      }
    } else {
      setCalendarShouldShow(!calendarShouldShow);
    }
  }

  function handleDestinationChange(event) {
    const input = event.target.value;
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
    setDestination(filteredInput);
  }

  function handleKeyPress(event) {
    const regex = /^[a-zA-Z\s]$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  function preventMail(event) {
    const regex = /^[a-zA-Z0-9@.]$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  function generateTour() {
    if (destination === "" || dates.length === 0) {
      alert("Please enter data");
      return;
    }
    const uniqueID = generateUnqiueID();
    const startDate = dates[0];
    const toDate = dates[1];
    window.location.href = `/tour?uniqueID=${uniqueID}&destination=${destination}&startDate=${startDate}&endDate=${toDate}&interests=${interestsSelected}`;
  }

  const tourdata = [
    <MainTourBlock
      destination="Venice, Italy"
      length="6"
      img="https://lh3.googleusercontent.com/places/ANXAkqHm_anI2tWes5b4YaqNPe88OOzX1lrwUcxvF6ZG_9XYGphHpNLoBh7D9Ihyx3b_ihFhEY0rhSS1W_mEIkONI-Q20rUyo3gqvRc=s1600-w1079"
      tour={`${currentUrl}/tour?uniqueID=1725975557935337903&destination=venice&startDate=2024-09-10&endDate=2024-09-15&interests=`}
    />,
    <MainTourBlock
      destination="Athens, Greece"
      length="4"
      img="https://www.outlooktravelmag.com/media/athens-1-1583767834.profileImage.2x-scaled.webp"
      tour={`${currentUrl}/tour?uniqueID=1725975565838436480&destination=athens&startDate=2024-09-10&endDate=2024-09-13&interests=`}
    />,
    <MainTourBlock
      destination="Toronto, Canada"
      length="6"
      img="https://media.cntraveler.com/photos/5b2c0684a98055277ea83e26/1:1/w_2667,h_2667,c_limit/CN-Tower_GettyImages-615764386.jpg"
      tour={`${currentUrl}/tour?uniqueID=1725975578192498431&destination=toronto&startDate=2024-09-17&endDate=2024-09-22&interests=`}
    />,
    <MainTourBlock
      destination="Paris, France"
      length="12"
      img="https://lh3.googleusercontent.com/places/ANXAkqEeitTfldRS-6FNdSp3PwjQLrGwMPaEjS9SBODO3Eg7uXkfw6ksryCN_CxBte90uRDPRokzMCxhiovZNLzw5NgCyRoEhqT2KY8=s1600-w1920"
      tour={`${currentUrl}/tour?uniqueID=1725975595244520272&destination=paris&startDate=2024-09-10&endDate=2024-09-21&interests=`}
    />,
    <MainTourBlock
      destination="Barcelona, Spain"
      length="8"
      img="https://earth.esa.int/web/earth-watching/content/documents/257246/1608677/Barcelona.jpg"
      tour={`${currentUrl}/tour?uniqueID=1725975606234780350&destination=barcelona&startDate=2024-09-10&endDate=2024-09-17&interests=`}
    />,

    <MainTourBlock
      destination="Tbilisi, Georgia"
      length="2"
      img="https://lh3.googleusercontent.com/places/ANXAkqHZT-d490MxJhAldUjK6jdQKw5KiQxPQiLF52WsoBMzlxl_2peIDSVRwN_cg_Wc3QDNJrENPtz_JbmKGBwb6LJGKHYkhwgxnag=s1600-w4032"
      tour={`${currentUrl}}/tour?uniqueID=1725975613559146790&destination=tbilisi&startDate=2024-10-16&endDate=2024-10-17&interests=`}
    />,

    <MainTourBlock
      destination="Sydney, Australia"
      length="4"
      img="https://lh3.googleusercontent.com/places/ANXAkqGEd66uhl6NW_O0xOfwqamFbt5mUGx9tvMTOSl6lJu2Di5TU_uFYJw7a_QRXL9dNN9q7so7ABhrGWXiR9G2HlLMheadEsoZ59w=s1600-w4344"
      tour={`${currentUrl}/tour?uniqueID=1725975622188620488&destination=sydney&startDate=2024-10-01&endDate=2024-10-04&interests=`}
    />,
  ];

  function subscribeTree() {
    if (subscribeTreeMail === "") {
      alert("Please enter your email");
      return;
    }
    const api = `${process.env.REACT_APP_API_URL}/subscribe`;
    const postData = {
      email: subscribeTreeMail,
      type: "tree",
    };

    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "subscribed") {
          setSubscribeVisible(true);
        } else {
          setSubscribeVisibleExists(true);
        }
      });
  }

  return (
    <div>
      {subscribeVisible && (
        <div className={s.subscribePopupWrapper}>
          <div className={s.subscribeInnerWrapper}>
            <img
              onClick={() => {
                setSubscribeVisible(false);
              }}
              src={closeCircle}
            />
            <h1>Thank You for Subscribing</h1>
            <p>
              We're thrilled to have you join our community. Stay tuned for the latest updates, exclusive offers, and
              more straight to your inbox.
            </p>
            <p>Feel free to explore and enjoy our content. If you have any questions, don't hesitate to reach out!</p>
          </div>
        </div>
      )}
      {subscribeVisibleExists && (
        <div className={s.subscribePopupWrapper}>
          <div className={s.subscribeInnerWrapper}>
            <img
              onClick={() => {
                setSubscribeVisibleExists(false);
              }}
              src={closeCircle}
            />
            <h1>Thank You for Subscribing</h1>
            <p>You have already subscribed with this email</p>
          </div>
        </div>
      )}
      {loaded ? (
        <div className={s.pageWrapper}>
          <div className={s.heroDiv}>
            {calendarShouldShow ? (
              <div className={s.datePickerDiv} onClick={handleCalendarClick}>
                <VanillaCalendar config={calendarConfig} />
                <button onClick={setDatesClick} className={s.saveDatesButton}>
                  Save dates
                </button>
              </div>
            ) : (
              <></>
            )}

            <Header />

            <div className={s.heroTextDiv}>
              <h1>{data.title}</h1>
              <h2>{data.subtitle}</h2>
              <p>{data.benefits}</p>
            </div>

            <div className={s.inputDiv}>
              <div className={s.inputOutterDiv}>
                <div className={s.inputInnerDiv}>
                  <div className={s.destinationInputDiv}>
                    <img src={location} alt="location" className={s.destinationIcon} />
                    <input
                      type="text"
                      placeholder="Your destination"
                      className={s.destinationInput}
                      onChange={handleDestinationChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className={s.destinationCalendarDiv}>
                    <img src={calendar} alt="calendar" className={s.calendarIcon} />
                    <button className={s.selectDatesButton} onClick={handleCalendarClick}>
                      {datesString}
                    </button>
                  </div>
                  <div onClick={generateTour} className={s.generateButton}>
                    <img src={star} alt="star" />
                    <p>Plan a trip</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={s.interestsDiv}>
              <div className={s.interestsWrapper}>
                {interests.map((interest) => (
                  <button onClick={handleInterestClick} className={s.interestButton}>
                    {interest}
                  </button>
                ))}
              </div>
            </div>

            <div className={s.heroFooterDiv}>
              {window.innerWidth > 500 ? (
                <ul>
                  <li>AI-Powered Itineraries</li>
                  <li>Accommodation</li>
                  <li>Experiences</li>
                  <li>Flights</li>
                </ul>
              ) : (
                <div className={s.mobileFooter}>
                  <ul>
                    <li>AI-Powered Itineraries</li>
                    <li>Accommodation</li>
                  </ul>
                  <ul>
                    <li>Experiences</li>
                    <li>Flights</li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className={s.plantTreeDiv}>
            <div className={s.plantTreeLeftside}>
              <h1 className={s.treeTitle}>{data["tree title"]}</h1>
              <h2 className={s.treeDesc}>{data["tree description"]}</h2>
              <h2 className={s.treeDesc2}>Subscribe to get more info</h2>
              <div className={s.plantTreeMailDiv}>
                <img src={mail}></img>
                <input
                  onKeyPress={preventMail}
                  onChange={(e) => setSubscribeTreeMail(e.target.value)}
                  type="text"
                  placeholder="Your Email"
                ></input>
                <button onClick={subscribeTree}>Subscribe</button>
              </div>
            </div>
            <div className={s.plantTreeDivRightside}>
              <p>{data["tree number"]}</p>
              <p>Carbon absorbed by trees</p>
              <div className={s.treeWrapper}>
                <img className={s.treeSvg} src={bigtree}></img>
              </div>
            </div>
          </div>

          <div className={s.gtDiv}>
            <img src={plane} alt="plane" className={s.plane} />
            <div className={s.gtHeadDiv}>
              <div className={s.gtTitleWrapper}>
                <h1>{data["generated tours title"]}</h1>
              </div>
              <h2 className={s.gtDesc}>{data["generated tours description"]}</h2>
            </div>

            <div className={s.gtToursDiv}>
              {Array.from({ length: 50 }).map((_, i) => tourdata.map((tour, index) => tour))}
            </div>
          </div>

          <div className={s.numbersDiv}>
            <h1 className={s.numbersTitle}>{data["numbers title"]}</h1>
            <h2 className={s.numbersDesc}>{data["numbers description"]}</h2>
            <div className={s.numbers}>
              <div className={s.scheduled}>
                <IncreasingNumber targetNumber={generatedTripsNumber} />
                <p>Generated Trips</p>
              </div>
              <div className={s.countries}>
                <IncreasingNumber targetNumber={destinationsNumber} />
                <p>Destinations</p>
              </div>
              <div className={s.treesplanted}>
                <IncreasingNumber targetNumber={data["numbers trees number"]} />
                <p>Trees planted</p>
              </div>
            </div>
          </div>

          <div className={s.sustDiv}>
            <div className={s.susDescDiv}>
              <h1 className={s.susTitle}>{data["sustainibility title"]}</h1>
              <p className={s.susDesc}>{data["sustainibility description"]}</p>
            </div>
            <img className={s.balloon1} src={balloon} />
            <img className={s.balloon2} src={balloon} />
            <div className={s.sustcardsWrapper}>
              <div className={`${s.sustCard1} ${s.sustCard}`}>
                <p>SDG 8</p>
                <p>Target: {data["sdg 8 target"]}</p>
                <p>{data["sdg 8 text"]}</p>
              </div>
              <div className={`${s.sustCard2} ${s.sustCard}`}>
                <p>SDG 17</p>
                <p>Target: {data["sdg 17 target"]}</p>
                <p>{data["sdg 17 text"]}</p>
              </div>
              <div className={`${s.sustCard3} ${s.sustCard}`}>
                <p>SDG 13</p>
                <p>Target: {data["sdg 13 target"]}</p>
                <p>{data["sdg 13 text"]}</p>
              </div>
            </div>
          </div>

          <div className={s.sponsorDiv}>
            <Sponsor image={booking} url="https://www.booking.com/index.html?aid=7905319" />
            <Sponsor image={jot} url="https://www.justonetree.life/" />
            <Sponsor image={freetour} url="https://www.freetour.com/?referralID=6u2reWJP6B8l56Ik&campaign=partner" />
            <Sponsor
              image={hostelworld}
              url="https://www.hostelworld.com/?source=affiliate-PHG-1100l3wJYr&affiliate=PHG&ref_id=1100lzyVrZKo&uniqueclickID=1100lzyVrZKo&utm_content="
            />
            <Sponsor
              image={viator}
              url="https://www.viator.com/?pid=P00148393&mcid=42383&medium=link&campaign=partners"
            />
            <Sponsor special="toyota" image={toyota} url="https://www.toyota.ge/" />
            <Sponsor image={expedia} url="https://expedia.com/affiliate/uWlyoKX/" />
            <Sponsor image={googleLogo} url="https://cloud.google.com/" />

            <Sponsor image={booking} url="https://www.booking.com/index.html?aid=7905319" />
            <Sponsor image={jot} url="https://www.justonetree.life/" />
            <Sponsor image={freetour} url="https://www.freetour.com/?referralID=6u2reWJP6B8l56Ik&campaign=partner" />
            <Sponsor
              image={hostelworld}
              url="https://www.hostelworld.com/?source=affiliate-PHG-1100l3wJYr&affiliate=PHG&ref_id=1100lzyVrZKo&uniqueclickID=1100lzyVrZKo&utm_content="
            />
            <Sponsor
              image={viator}
              url="https://www.viator.com/?pid=P00148393&mcid=42383&medium=link&campaign=partners"
            />
            <Sponsor special="toyota" image={toyota} url="https://www.toyota.ge/" />
            <Sponsor image={expedia} url="https://expedia.com/affiliate/uWlyoKX/" />
            <Sponsor image={googleLogo} url="https://cloud.google.com/" />
          </div>

          <Footer setSubscribeVisible={setSubscribeVisible} setSubscribeVisibleExists={setSubscribeVisibleExists} />
        </div>
      ) : (
        <TourLoader />
      )}
      ;
    </div>
  );
};

export default Home;
