import React from "react";
import { useState, useEffect } from "react";
import background from "../assets/svg/WEB Hero.svg";
import s from "../style/savedtrips.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import location from "../assets/svg/location.svg";
import calendar from "../assets/svg/calendar.svg";

export const SavedTrips = () => {
  const [logStatus, setLogStatus] = useState(false);
  const [trips, setTrips] = useState([]);
  const [sum, setSum] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("id")) {
      setLogStatus(true);
    } else {
      setLogStatus(false);
    }
  }, []);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/myTrips`;
    const email = localStorage.getItem("email");
    const postData = { email: email };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        setTrips(data);
        let sum = 0;
        for (let i = 0; i < data.length; i++) {
          let carbon_footprint = parseFloat(data[i]["carbon_footprint"]);
          sum += isNaN(carbon_footprint) ? 0 : carbon_footprint;
        }
        const onlyTwoDecimals = sum.toFixed(2);
        setSum(onlyTwoDecimals);
      });
  }, [logStatus]);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    if (isNaN(date)) {
      return "Invalid Date";
    }
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthAbbr = months[date.getMonth()];
    const day = date.getDate();
    return `${monthAbbr} ${day}`;
  }

  return logStatus ? (
    <>
      <div className={s.hero}>
        <Header />
        <p className={s.firstText}>Saved Trips</p>
        <p className={s.secondText} >{sum} MtCO2e</p>
        <p className={s.thirdText} >Carbon Footprint Reduction</p>
        <img className={s.backgroundImg} src={background} />
      </div>

      <div className={s.body}>
        {trips.map((trip, index) => {
          const tripUrl = trip.tour_link;
          const url = new URL(tripUrl);
          const params = new URLSearchParams(url.search);
          const startDate = params.get("startDate");
          const endDate = params.get("toDate");
          const datesString = formatDate(startDate) + " - " + formatDate(endDate);
          return (
            <div key={index} className={s.tripBox} onClick={() => window.open(`${trip["tour_link"]}`, "_blank")}>
              <img className={s.tripBackground} src={trip["img"]} alt={`Trip to ${trip["destination"]}`} />
              <div>
                <img src={location} alt="Location icon" />
                <p>{trip["destination"]}</p>
              </div>
              <div>
                <img src={calendar} alt="Calendar icon" />
                <p>{datesString}</p>
              </div>
            </div>
          );
        })}
      </div>

      <Footer />
    </>
  ) : (
    <p>Log in first</p>
  );
};
